import './src/styles/fonts.css';
import './src/styles/normalise.min.css';
import './src/styles/main.css';

import React from 'react';
import Layout from './src/components/layout';

const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export { wrapPageElement };
