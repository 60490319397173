import React, { useState } from 'react';
import ContactContext from '../contexts/contact-context';

const Layout = ({ children }) => {
  const dialogState = useState(null);

  return (
    <ContactContext.Provider value={dialogState}>
      {children}
    </ContactContext.Provider>
  );
};

export default Layout;
